[data-theme-version="dark"] {
  background: url("../images/body_background.jpg") no-repeat;
  background-size: cover;
}

body {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.deznav {
  /* background-color: transparent; */
}

.table-export-button-excel {
  background-color: #009800;
  color: #ffffff;
}

.table-export-button-excel:hover {
  background-color: #52d352;
  color: #ffffff;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-responsive {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.dataTables_wrapper .dataTables_paginate>.paginate_button {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 50% !important;
  padding: 0;
  font-size: 1rem;
}

.sorting-table-container {
  overflow-x: scroll;
}

.sorting-table-container::-webkit-scrollbar {
  height: 1.25rem !important;
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 0.25rem;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px var(--shadow-black);  */
  border-radius: 0.25rem;
}

.sorting-table-container::-webkit-scrollbar-thumb {
  height: 0.5rem !important;
  background-color: var(--primary);
  border: 1px solid var(--white);
  border-radius: 0.25rem;
  cursor: pointer;
}

.sorting-table-container::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 5px #2b2b2b6d;
}

.color-box {
  border-radius: 0.75em;
  padding: 5px 7px;
  background-color: var(--color-bg);
  color: white;
}

.icon-box {
  width: 4em;
  height: 4em;
  border-radius: 0.75em;
  border: 1px solid #909090;
}

.profile-image-box {
  width: 6em;
  height: 6em;
  border-radius: 0.75em;
  border: 1px solid #909090;
}

.profile-image-box>img {
  border-radius: inherit;
}

.color-box-input {
  height: 5em;
  width: 5em;
  padding: 1em;
}

.error {
  color: red;
}

.h-25-vh {
  height: 25vh !important;
}

.h-50-vh {
  height: 50vh !important;
}

.h-75-vh {
  height: 75vh !important;
}

.h-100-vh {
  height: 100vh !important;
}

.member-list-container {
  overflow-y: scroll;
  overflow-x: none;
}

.list-view-container {
  overflow-y: scroll;
  overflow-x: none;
}

.list-view-container::-webkit-scrollbar {
  display: none;
}

.nomination-list-container {
  height: 75vh;
  background-color: #eeeeee;
  border-radius: 5px;
}

.nomination-list-container::-webkit-scrollbar {
  display: none;
}

.nomination-container {
  border-left: 1px solid #eeeeee;
}

.agent-member-container-listview,
.member-policy-container-listview,
.company-policy-container-listview,
.reference-container-listview {
  overflow-y: scroll;
  overflow-x: none;
}

.agent-member-container-listview>div:not(:last-child),
.member-policy-container-listview>div:not(:last-child),
.company-policy-container-listview>div:not(:last-child) {
  border-bottom: 1px solid #c1c1c1;
}

.collapse-custom-header-container {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-radius: 0;
}

.collapse-custom-header-container:hover {
  font-weight: 800;
}

.collapse-custom-body-container {
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}

.collapse-custom-body-container>div>div>div:not(:last-child) {
  border-bottom: 1px solid #c1c1c1;
}

.create-company-policy-container {}

.left-border-only,
.company-policy-listview {
  border-left: 1px solid #eeeeee;
}

.questionnaire-list {
  overflow-y: scroll;
  overflow-x: none;
}

.questionnaire-list-container {
  border-top: 1px solid #eeeeee;
}

/* special buttons */
.cancel-button-icon {
  color: #ff0000;
}

.cancel-button-icon:hover {
  color: #ff000073;
}

.move-up-button-icon,
.move-down-button-icon {
  color: #7b7b7b;
  cursor: pointer;
}

.move-up-button-icon:hover,
.move-down-button-icon:hover {
  color: #bcbcbc;
}

/* font larger */
.font-25-bigger {
  font-size: 1.25em;
}

.rider-reference-container {
  border-radius: 0.25em;
  border: 1px solid #bcbcbc;
  padding: 5px;
}

.pagination {
  justify-content: center;
  align-items: center;
}

.pagination a {
  padding: 10px;
  border-right: none;
  border-left: none;
  background: transparent;
  border-radius: 6px;
  margin: 0px 3px;
  color: #5027d3 !important;
}

.pagination li {
  padding: 10px 5px;
}

.pagination li a {
  padding: 10px 12px;
  border: 1.5px solid;
  border-color: #4f00d4;
}

.pagination li.previous a,
.pagination li.next a {
  border: 0;
}

.pagination__link--active {
  border-radius: 4px;
}

.pagination .pagination__link--active a,
.pagination li a:hover {
  border: 1.5px solid transparent;
  background: linear-gradient(180deg, #4f00d4 -18.21%, #53dacd 277.2%);
  padding: 10px 12px;
  color: #fff !important;
}

.previous,
.next {
  width: 3rem;
  text-align: center;
}

/* .img-border {
    border: 1px solid #f2f2f2;
} */

.w-image-fluid {
  object-fit: cover;
  max-width: 100%;
}

.auto-complete-close {
  position: absolute;
  right: 10px;
  bottom: 13px;
  /* font-size: 1px; */
  cursor: pointer;
}

/* Product Template CSS */
.product-template-box .product-category {
  right: 7px;
  top: 7px;
  font-weight: 500;
  color: #fff;
  padding: 5px 15px;
  text-align: center;
  border-radius: 5px;
  background: var(--linear, linear-gradient(180deg, #a400ff 0%, #53dacd 100%));
  font-size: 10px;
}

.product-template-box {
  padding: 15px;
}

.product-row .product-template-box {
  cursor: pointer;
}

.product-row .product-template-box:hover {
  background-color: #171622;
  opacity: 0.7;
}

.input-balance {
  padding-right: 80px;
}

.input-label-balance {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  padding: 0.3125rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.select-phone-code {
  position: absolute;
  left: 0;
  width: auto;
  background-color: #ccc;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-phone-code {
  padding-left: 70px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.align-content-space-between {
  align-content: space-between;
}

.justify-content-space-between {
  justify-content: space-between;
}

.misc-form-group {
  border-bottom: 1px solid #eee;
}

.misc-form-group:last-child {
  border-bottom: 0;
}

.filter_sort {
  position: relative;
}

.filter_sort::after {
  content: "\f0de";
}

.filter_sort::before {
  content: "\f0dd";
}

.filter_sort::after,
.filter_sort::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: -20px;
}

.filter_sort.asc::before {
  content: "";
}

.filter_sort.desc::after {
  content: "";
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.loader-div {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 60px;
}

.loader-div.loader-lg-div {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.loader-div.loader-dasboard-div {
  position: relative;
  min-height: 592px;
}

.metismenu>li.mm-active>a i {
  font-weight: 900 !important;
}

.deznav .metismenu>li.mm-active>a.ai-icon,
.deznav .metismenu>li.mm-active>a.has-arrow,
.deznav .metismenu>li:hover>a.ai-icon,
.deznav .metismenu>li:hover>a.has-arrow {
  background-color: transparent !important;
  box-shadow: none;
  color: #ffffff;
}

.deznav .metismenu>li.mm-active>a i,
.deznav .metismenu>li:hover>a i {
  color: #a400ff !important;
}

.deznav .metismenu ul a.mm-active {
  padding-left: 5.5rem;
}

.deznav .metismenu ul a.mm-active:before {
  width: 1.25rem;
}

.btn {
  border-radius: 7px;
  padding: 7px 25px;
  /* font-size: 15px; */
}

.btn-categories {
  border-radius: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: transparent !important;
  border: 1px solid #a400ff !important;
}

.btn-categories.active {
  background: linear-gradient(180deg, #a400ff -18.21%, #53dacd 277.2%) !important;
  border-color: transparent !important;
}

.btn-categories.active::after {
  content: "✔";
  display: inline-block;
  margin-left: 3px;
}

.btn-xs {
  padding: 5px 15px;
}

.label-primary {
  background: linear-gradient(180deg, #a400ff -18.21%, #53dacd 277.2%);
}

.btn-primary {
  background: linear-gradient(180deg, #a400ff -18.21%, #53dacd 277.2%);
  border-color: transparent;
}

[data-theme-version="dark"] .form-control {
  border-width: 1px;
}

textarea.form-control {
  min-height: unset;
  height: unset;
}

table.table {
  color: #fff;
  border-color: #3b3b3b;
  box-shadow: 2px 2px 2px 0px #ffffff1a inset;
  border-radius: 20px;
}

.table thead td {
  border-bottom-color: #3b3b3b !important;
}

.table tbody tr td {
  border-color: #3b3b3b !important;
}

.table th,
.table td {
  padding: 13px 30px;
}

.form-control {
  border: 1px solid #9391ae !important;
  border-radius: 7px;
  height: 40px;
}

.form-control::placeholder {
  color: #797979 !important;
}

.date-picker-custom-form-control,
.custom-form-control>div.css-yk16xz-control {
  background-color: transparent;
  border: 1px solid #9391ae;
  border-radius: 7px;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

.date-picker-custom-form-control {
  padding: 7px 15px;
}

.date-picker-custom-form-control input,
.date-picker-custom-form-control span {
  color: #ffffff !important;
}

.total-custody-bg {
  background-image: url("../images/total_custody_backgroud.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.total-expired-custody-bg {
  background-image: url("../images/total_expired_custody_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.total-follower-bg {
  background-image: url("../images/total_followers_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.filter-chart {
  width: 150px;
}

.filter-chart .css-1uccc91-singleValue {
  color: #ffffff;
}

.css-1pahdxg-control {
  background-color: transparent !important;
  color: #ffffff;
}

.pdf-template-div .report-div {
  margin-top: 0px;
}

.pdf-template-div table.report-table {
  color: #969ba0;
  width: 100%;
}

.pdf-template-div table.report-table thead th {
  font-size: 13px;
  border-bottom: 2px solid #bfbfbf !important;
  border-top: 2px solid #bfbfbf !important;
  color: #000000;
}

.pdf-template-div {
  color: #000000;
  font-size: 12px;
  padding: 15px 0;
}

.pdf-template-div table.report-table th,
.pdf-template-div table.report-table td {
  max-width: 100px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  color: #444444;
}

/* Card Deck */
.pricing-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* .pricing-deck .pricing-card {
    min-width: 220px;
} */

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #a400ff;
  border-color: #a400ff;
}

/* .pricing-card {
    border: 1px solid #fff;
    border-radius: 3px;
    padding-top: 15px;
} */

.pricing-card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.pricing-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.pricing-card-header .discount-text {
  height: 17px;
  display: block;
}

.pricing-card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.pricing-card-footer {
  padding: 1.25rem;
}

.ReactModal__Overlay {
  z-index: 9;
}

@media (min-width: 576px) {
  .pricing-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .pricing-deck .pricing-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

/* Card Deck */

.create-custody-table td {
  padding: 10px 15px;
}

.profile-div {
  margin-left: 15px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .create-custody-table {
    width: 150%;
  }
}

@media screen and (max-width: 767px) {
  .profile-div {
    font-size: 12px;
  }
}

@media screen and (max-width: 475px) {
  .create-custody-table {
    width: 200%;
  }

  .profile-div {
    font-size: 10px;
  }
}

.waze-url-link {}

.waze-url-link:hover .waze-guide-div {
  display: block;
}

.waze-guide-div {
  display: none;
  bottom: 70px;
  z-index: 9;
}

.waze-guide-div img {
  /* max-width: 175%; */
}

@media screen and (max-width: 767px) {
  .waze-guide-div img {
    /* max-width: 100%; */
  }
}

.form-group.required label.form-label::after,
td.required-col::after {
  content: "*";
  color: #ff6746;
  padding-left: 3px;
}

.header-right .header-profile .nav-link {
  border-left: 0;
}

.package {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 11px;
  height: 100%;
}

.package-active {
  border: 1px solid #c280ff;
  box-shadow: 0px 0px 3px 1px #8500ff;
}

.remove-image-a {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-template-image {
  height: 200px;
}

.product-template-image img {
  height: 100%;
}